import React, { useCallback, useMemo } from 'react';
import { useMe } from '@brainstud/academy-api/Hooks/useMe';
import { useSubscriptionPromotion } from '@brainstud/academy-api/Hooks/useSubscriptionPromotions';
import { Button } from '@brainstud/ui/Buttons/Button';
import { Banner } from '@brainstud/ui/Static/Banner';
import classNames from 'classnames/bind';
import { COACH_ROLES } from 'Config/roles';
import { useHasAnyRole } from 'Hooks/Roles/useHasAnyRole';
import { useToaster } from 'Providers/ToasterProvider/useToaster';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';
import { durationConversion } from 'Utils/durationConversion';
import styles from './SubscriptionBanner.module.css';

const cx = classNames.bind(styles);

const NOTIFICATION_DAYS = 15;

/**
 * SubscriptionBanner.
 *
 * Shows a banner based on the current subscription of the user.
 */
export const SubscriptionBanner = () => {
  const [t] = useTranslator();
  const [me] = useMe();
  const [setToast] = useToaster();
  const activatePromotion = useSubscriptionPromotion();
  const isLoggedIn = !!me?.account;
  const showExpiryBannerAllowed = me?.showSubscriptionExpiryBanner;
  const subscription = useMemo(() => me?.subscription?.(), [me]);
  const endDateString = subscription?.endDate;
  const hasPromotionsAvailable = !!me?.subscriptionPromotionAvailable;
  const isCoach = useHasAnyRole(COACH_ROLES);
  // This check is the same as in the backend
  const duration = isCoach ? '10 days' : '2 days';

  const subscriptionValidDays = useMemo(
    () =>
      endDateString
        ? DateFormatter.differenceIn('days', endDateString, new Date())
        : -1,
    [endDateString]
  );

  const hasEnded = useMemo(() => {
    const endDate = endDateString && new Date(endDateString);
    return endDate ? endDate < new Date() : false;
  }, [endDateString]);

  const shouldNotify = subscriptionValidDays < NOTIFICATION_DAYS;
  const showBanner = isLoggedIn && showExpiryBannerAllowed && shouldNotify;

  const handlePromotionActivation = useCallback(async () => {
    activatePromotion.mutate(
      {},
      {
        onSuccess: () => {
          setToast(
            t('components.subscription_banner.promotion.success'),
            'success'
          );
        },
        onError: () => {
          setToast(
            t('components.subscription_banner.promotion.error'),
            'error'
          );
        },
      }
    );
  }, [activatePromotion, setToast, t]);

  return !showBanner ? null : (
    <Banner warning={!hasEnded} error={hasEnded}>
      {!hasEnded
        ? `${t(`components.subscription_banner.expires.${subscriptionValidDays === 0 ? 'today' : subscriptionValidDays > 1 ? 'plural' : 'single'}`, { days: subscriptionValidDays })} ${t('components.subscription_banner.expires_exam_notice')}`
        : t('components.subscription_banner.expired')}
      <Banner.Actions>
        <Button white href="/account/subscriptions/options">
          <span>{t('components.subscription_banner.extend_subscription')}</span>
        </Button>
        {hasPromotionsAvailable && (
          <Button
            className={cx(styles.button)}
            type="button"
            white
            loading={activatePromotion.isPending}
            onClick={handlePromotionActivation}
          >
            <span>
              {t(...durationConversion(duration))} <span>{t('free')}!</span>
            </span>
          </Button>
        )}
      </Banner.Actions>
    </Banner>
  );
};
