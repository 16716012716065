import React, { useCallback, useMemo, useRef } from 'react';
import AnimateHeight from 'react-animate-height';
import { useMe } from '@brainstud/academy-api/Hooks/useMe';
import { Button } from '@brainstud/ui/Buttons/Button';
import { Close } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import classNames from 'classnames/bind';
import { Container } from 'Components/Container';
import { useTranslator } from 'Providers/Translator';
import { usePortalParameter } from '../../Hooks/usePortalParameter';
import useFooterLink from '../../Providers/FooterLinkProvider/useFooterLink';
import Faq from '../../Views/HomeView/Sections/Faq/Faq';
import styles from './Footer.module.css';

const cx = classNames.bind(styles);

const Footer = () => {
  const beforeVatPricesUsed = !!usePortalParameter('show_prices_excluding_vat');
  const [t] = useTranslator();
  const [me] = useMe();
  const isLoggedIn = useMemo(() => Boolean(me?.account), [me]);
  const { faqDrawer, setFaqDrawer, contactDrawer, setContactDrawer } =
    useFooterLink();
  const contact = useRef<null | HTMLDivElement>(null);
  const faq = useRef<null | HTMLElement>(null);

  const portal = useMemo(() => me?.portal?.(), [me]);
  const contactData = portal?.contactInformation;

  const privacyDocumentUrl = useMemo(
    () =>
      portal?.parameters.find((p) => p.type === 'privacy_document_url')?.data,
    [portal]
  );

  const tacDocumentUrl = useMemo(
    () => portal?.parameters.find((p) => p.type === 'tac_document_url')?.data,
    [portal]
  );

  const toggleContact = useCallback(
    (event: any) => {
      event.preventDefault();
      setContactDrawer((prevDrawer) => !prevDrawer);
    },
    [setContactDrawer]
  );

  const toggleFaq = useCallback(
    (event: any) => {
      event.preventDefault();
      setFaqDrawer((prevFaq) => !prevFaq);
    },
    [setFaqDrawer]
  );

  return (
    <div className={cx(styles.base)}>
      <AnimateHeight
        height={!isLoggedIn ? 'auto' : faqDrawer ? 'auto' : 0}
        duration={400}
        onHeightAnimationEnd={() => {
          faq?.current?.scrollIntoView();
        }}
      >
        <section ref={faq} className={styles.sectionLight}>
          <Container>
            <Faq onClose={() => (setFaqDrawer ? setFaqDrawer(false) : null)} />
          </Container>
        </section>
      </AnimateHeight>

      <AnimateHeight
        height={contactDrawer ? 'auto' : 0}
        duration={400}
        onHeightAnimationEnd={() => {
          contact?.current?.scrollIntoView();
        }}
      >
        <div ref={contact} className={cx(styles.contact)}>
          <Container>
            <header>
              <h1>{t('components.footer.contact.heading')}</h1>
              <Button
                quiet
                onClick={() =>
                  setContactDrawer ? setContactDrawer(false) : null
                }
              >
                <Close />
              </Button>
            </header>
            <Grid container>
              <Grid item xs={6} md={3}>
                <span className={cx('title')}>
                  {/* @ts-ignore FIXME when type companyName is updated to company */}
                  <strong>{contactData?.company}</strong>
                </span>
              </Grid>
              <Grid item xs={12} md={4}>
                <span>{contactData?.address?.street}</span>
                <span>{`${contactData?.address?.postalCode} ${contactData?.address?.city}`}</span>
              </Grid>
              <Grid item xs={12} md={5}>
                <Button link href={`tel:${contactData?.phoneNumber}`}>
                  {contactData?.phoneNumber}
                </Button>
                <Button link href={`mailto:${contactData?.emailAddress}`}>
                  {contactData?.emailAddress}
                </Button>
              </Grid>
            </Grid>
          </Container>
        </div>
      </AnimateHeight>

      <section id="footer" className={cx('footer')}>
        <Container>
          <Grid container>
            <Grid item xs={6} md={4}>
              <p>{contactData?.imprint}</p>
              {beforeVatPricesUsed && (
                <span>{t('components.footer.vat_prices')}</span>
              )}
            </Grid>
            <Grid item xs={6} md={8}>
              <ul>
                {privacyDocumentUrl && (
                  <li>
                    <a
                      target="_blank"
                      href={privacyDocumentUrl}
                      rel="noreferrer"
                    >
                      {t('components.footer.functional_link_1')}
                    </a>
                  </li>
                )}
                {tacDocumentUrl && (
                  <li>
                    <a target="_blank" href={tacDocumentUrl} rel="noreferrer">
                      {t('components.footer.functional_link_2')}
                    </a>
                  </li>
                )}
                <li>
                  <Button
                    link
                    className={styles['footer-link']}
                    onClick={toggleContact}
                  >
                    {t('components.footer.link_on_the_right_1')}
                  </Button>
                </li>
                {isLoggedIn && (
                  <li>
                    <Button
                      link
                      className={styles['footer-link']}
                      onClick={toggleFaq}
                    >
                      {t('components.footer.link_on_the_right_2')}
                    </Button>
                  </li>
                )}
              </ul>
            </Grid>
          </Grid>
        </Container>
      </section>
    </div>
  );
};

export default Footer;
