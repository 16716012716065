import { UseMutationOptions } from '@tanstack/react-query';
import { useCreate } from '../Factories/useCreate';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import { UUID } from '../Types/Utils/UUID';

interface Props {
  account?: UUID;
  portal?: UUID;
}

type Input = Props | undefined;

export function useSubscriptionPromotion(
  { account, portal }: Props = {},
  settings?: UseMutationOptions<DataDocument<undefined>, ErrorDocument, Input>
) {
  const context = useApi();
  const {
    parameters: { account: defaultAccount, portal: defaultPortal },
  } = context;
  return useCreate<undefined, Input>(
    new ApiRequest(
      {
        baseName: 'v1.services.subscriptionPromotionActivation',
        baseUri: 'v1/services/subscription_promotion_activation',
        invalidate: [
          'v1.me',
          'subscriptions',
          'subscription',
          'order',
          'orders',
          'courses',
          'v1.portals.courses',
          'v1.courses',
        ],
        data: {
          account: account || defaultAccount,
          portal: portal || defaultPortal,
        },
      },
      context
    ),
    settings
  );
}
