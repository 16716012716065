import React, { CSSProperties, PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import { SubscriptionBanner } from 'Components/SubscriptionBanner';
import { useHeadProvider } from 'Providers/HeadProvider/useHeadProvider';
import styles from './CatalogLayout.module.css';

const cx = classNames.bind(styles);

type Props = {
  style?: CSSProperties;
  className?: string;
};

/**
 * CatalogLayout.
 *
 * Contains the main layout of the catalog.
 */
export const CatalogLayout = ({
  className,
  style,
  children,
}: PropsWithChildren<Props>) => {
  useHeadProvider('titles.catalog.main');

  return (
    <main className={cx(styles.base, className)} style={style}>
      <SubscriptionBanner />
      {children}
    </main>
  );
};
